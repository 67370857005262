<template>
  <div class="accounting-positions pt-4 pb-8 w-full">
      <p class="title">Positions Abrechnung</p>
      <vs-row class="pt-4">
        <vs-col vs-w="3">
          <vs-checkbox v-model="billingPromptShowArticleDescription">Artikel-Beschreibungen anzeigen?</vs-checkbox>
        </vs-col>
        <vs-col vs-w="3">
          <vs-checkbox v-model="billingPromptAddOfferReference2ProductName">Angebots-Bezug im Artikelname hinzufügen</vs-checkbox>
        </vs-col>
      </vs-row>
      <div class="position-container pt-4" v-if="billingOrderItems">
        <div v-for="(item,index) in billingOrderItems" class="position mb-2">
          <div class="w-full flex">
            <div class="w-3/4" @dblclick="onClickBillingItemInlineEdit(index)">
              <template v-if="billingInlineEditIndex != index">
                <div class="name" v-html="item.product_name">
                  {{item.product_name}}
                </div>
                <span v-if="billingPromptAddOfferReference2ProductName" class="reference2offer"> - Lt. Angebot {{order.invoice_number}} vom  {{ formatDate(order.order_date) }}</span>
                <div class="clearfix"> </div>
                <div class="description" v-html="item.short_description" v-show="billingPromptShowArticleDescription" >
                  {{item.short_description}}
                </div>
                <vs-alert color="#ff0000" :active="item.article != null && item.article.offer_description.length > 4" icon="error" class="mb-4 small-alert" >
                  Hinweis: Alternativer Rechnungstext im Artikel vorhanden. <vs-button color="danger" size="small" @click="openAlternativBillingDescriptionPopup(item)">anzeigen</vs-button>
                </vs-alert>
              </template>
              <template v-else>
                <vs-input size="small" v-model="billingProductItemClone.product_name" class="w-full mb-2"></vs-input>
                <vue-editor :editorToolbar="toolbar" v-model="billingProductItemClone.short_description" class="mb-2"></vue-editor>
                <div class="flex justify-end">
                  <vs-button size="small" @click="cancelBillingItemInlineEdit">Abbrechen</vs-button>
                  <vs-button size="small" color="success" @click="saveBillingItemInlineEdit">Speichern</vs-button>
                </div>
              </template>
            </div>
            <div class="w-2/6">
              <div class="w-full flex">

                <div class="w-3/5" v-bind:class="[item.is_accounting_finished  ? 'accounting_finished' : '']">
                  <div class="w-full flex" v-if="item.add_to_accounting">
                    <vs-input class="w-3/5 mr-4"
                              label-placeholder="Abzurechnende Menge" v-model="item.amount" @change="changeAmountInput(item)" data-vv-as="Abzurechnende Menge" :name="'billable_amount_' + index" v-validate="'decimal'"  />

                    <div class="w-2/5 pt-6">
                      {{unitName(item.volume_unit)}}
                    </div>
                  </div>
                  <span class="text-danger text-sm clearfix" v-if="errors.has('billable_amount_'+ index)">{{ errors.first('billable_amount_'+ index) }}</span>
                  <span class="amount">
                                                (Angebotene Menge {{item.offer_amount}}  {{unitName(item.volume_unit)}})
                                            </span>
                  <div class="alreadyAccounting mt-3" v-if="item.accountings && item.accountings.length >= 1">
                    <h5>Bereits abgerechnet:</h5>
                    <vs-table :data="item.accountings" :hoverFlat="true" class="mt-1">
                      <template slot="thead">
                        <vs-th>
                          RE-Nr.
                        </vs-th>
                        <vs-th>
                          Menge
                        </vs-th>
                        <vs-th>
                          Einzelpreis
                        </vs-th>

                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="tr">
                            <div v-if="data[indextr].order">
                              {{data[indextr].order.invoice_number}} <br>  {{ formatDate(data[indextr].order.order_date) }}
                            </div>
                            <div v-if="!data[indextr].order">
                              <span class="mg-label">In der Abrechnung</span>
                            </div>
                          </vs-td>

                          <vs-td :data="tr.amount">
                            {{data[indextr].amount}} {{unitName(data[indextr].volume_unit)}}
                          </vs-td>

                          <vs-td :data="tr.unit_price">
                            {{data[indextr].unit_price}} €
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
                <div class="w-2/5">
                  <vs-alert color="#8a8a8a" :active="item.is_accounting_finished == true || item.is_accounting_finished == 1" icon="warning" class="mb-4 small-alert" >
                    Diese Position wurde schon als "abgerechnet" markiert.
                  </vs-alert>

                  <vs-checkbox v-model="item.add_to_accounting" @change="changeAddToAccountingCheckbox(item)" v-if="!item.is_accounting_finished">Abrechnen</vs-checkbox>
                  <vs-button icon="check_circle_outline" color="success" size="small" class="mt-4" v-if="!item.is_accounting_finished" @click="saveOrderAccountingFinished(item)">Bereits vollständig abgerechnet!</vs-button>
                  <div class=" ml-2 mt-4" v-if="item.is_accounting_finished">
                                                <span class="link" @click="saveOrderAccountingNotFinished(item)">
                                                    Als <b>nicht</b> vollständig abgerechnet markieren
                                                </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <vs-row>
        <vs-col vs-justify="right"  vs-align="right" vs-w="12" class="mt-4 text-right">
          <vs-button  color="success" type="filled"
                      @click="onSubmitOfferItems2Accounting">Markierte Positionen abrechnen</vs-button>
        </vs-col>
      </vs-row>
      <vs-popup title="Alternative Beschreibung"    :active.sync="billingAlternativDescriptionPrompt" >

        <div v-if="selectedAlternativDescriptionItem" >
          <h3>Rechnungsbeschreibung</h3>
          <div v-html="selectedAlternativDescriptionItem.article.description">{{selectedAlternativDescriptionItem.article.description}}</div>
          <hr>
          <h3 class="mt-4">Angebotsbeschreibung</h3>
          <div v-html="selectedAlternativDescriptionItem.article.offer_description">{{selectedAlternativDescriptionItem.article.offer_description}}</div>
        </div>
      </vs-popup>
  </div>
</template>

<script>

import ApiService from "../../../../api";
import staticOptions from "../../../../mixins/static/options";
import moment from "moment";
import {mapGetters} from 'vuex';

export default {
  name: "AccountingPositions",
  components: {
  },
  data() {
    return {
      billingOrderItems: [],
      order: null,
      billingInlineEditIndex: null,
      billingPromptShowArticleDescription:true,
      billingPromptAddOfferReference2ProductName:true,
      billingAlternativDescriptionPrompt: false,
      selectedAlternativDescriptionItem: null,
      billingProductItemClone: null,
    }
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters([
      'workspace'
    ]),
  },
  methods: {
    fetchData() {
      const id = this.$route.params.id;
      this.$vs.loading()
      ApiService.get(`orders/${id}`).then(response => {
        this.order = response.data.result;
        this.$vs.loading.close()
        return this.getOrderItems();
      }).catch(response => {
      })
    },
    getOrderItems() {
      this.billingOrderItems=[];
      for(let i = 0;i < this.order.items.length; i++) {
        if(this.order.items[i].type == 'article' || this.order.items[i].type == 'group'){

          var item = JSON.parse(JSON.stringify(this.order.items[i]))
          if(this.order.items[i].optional_item == 0 && this.order.items[i].is_accounting_finished == false) {
            item["add_to_accounting"] = true;
          } else {
            item["add_to_accounting"] = false;
          }
          item["offer_amount"] = item["amount"];

          if(this.order.items[i].is_accounting_finished == 1 || this.order.items[i].is_accounting_finished == true) {
            item["amount"] = null;
          }
          this.billingOrderItems.push(item)
        }
      }
    },
    unitName(value) {
      let name = staticOptions.units.find((item) => item.value == value);

      if(name === null || name === undefined) {
        return null;
      }

      return name.labelShort;
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    onSubmitOfferItems2Accounting() {
      let items = [];

      for(let i = 0;i < this.billingOrderItems.length; i++) {
        if ((this.billingOrderItems[i].add_to_accounting == true || this.billingOrderItems[i].add_to_accounting == 1) && this.billingOrderItems[i].amount >= 0.01) {

          let item = JSON.parse(JSON.stringify(this.billingOrderItems[i]))

          item["client_id"] = this.order.client_id;
          item["workspace_id"] = this.workspace.id;

          if (this.billingPromptAddOfferReference2ProductName) {
            item["product_name"] = item["product_name"] + " - Lt. Angebot " + this.order.invoice_number + " vom " + this.formatDate(this.order.order_date);
          }
          if (!this.billingPromptShowArticleDescription) {
            item["short_description"] = null;
          }
          items.push(item);
        }
      }
      if(items.length === 0) {
          this.$vs.notify({
          title: 'Fehler',
          text: 'Keine Abrechnungen vorhanden',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      }
      if(items.length >= 1) {
        this.$vs.loading()
        ApiService.post('accountings/bulk', items).then((response) => {

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Abrechnungspositionen wurden erfolgreich angelegt',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });

          this.$vs.loading.close()
          this.billingPrompt = false;
          this.fetchData()

        }).catch((error) => {
          this.$vs.notify({
            title: 'Fehler',
            text: 'Die Abrechnungspositionen konnten nicht erstellt werden.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });

          this.$vs.loading.close()
        })


      }
    },
    onClickBillingItemInlineEdit(index){
      this.billingInlineEditIndex = index
      this.billingProductItemClone = JSON.parse(JSON.stringify(this.billingOrderItems[index]));
    },
    saveBillingItemInlineEdit() {
      this.billingOrderItems[this.billingInlineEditIndex].product_name = this.billingProductItemClone.product_name;
      this.billingOrderItems[this.billingInlineEditIndex].short_description = this.billingProductItemClone.short_description;
      this.billingInlineEditIndex = null;
      this.billingProductItemClone = null;
    },

    cancelBillingItemInlineEdit() {
      this.billingInlineEditIndex = null;
      this.billingProductItemClone = null;
    },
    saveOrderAccountingNotFinished(item){
      const id = item.id;
      const postData = {
        is_accounting_finished: false,
      };

      this.$vs.loading();

      ApiService.put(`order_items/${id}`, postData).then(response => {
        if (response.data.status === 'success') {
          this.$vs.loading.close();
          item.is_accounting_finished = false;
          item.amount = null;
          item.add_to_accounting = false;

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch(response => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
    saveOrderAccountingFinished(item){

      const id = item.id;
      const postData = {
        is_accounting_finished: true,
      };

      this.$vs.loading();

      ApiService.put(`order_items/${id}`, postData).then(response => {
        if (response.data.status === 'success') {
          this.$vs.loading.close();
          item.is_accounting_finished = true;
          item.amount = null;
          item.add_to_accounting = false;

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch(response => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
    changeAddToAccountingCheckbox(item){
      if(item.add_to_accounting == false) {
        item.amount = null;
      }
    },
    changeAmountInput(item){
      if(item.amount == 0 || item.amount.length == 0) {
        item.add_to_accounting = false;
      } else {
        item.add_to_accounting = true;
      }
    },
    openAlternativBillingDescriptionPopup(item){
      this.billingAlternativDescriptionPrompt = true;
      this.selectedAlternativDescriptionItem = item;

    }
  }
}
</script>
<style lang="scss">
.accounting-positions {
  .position {
    &:nth-of-type(even) {
      background: #efefef;
    }
    > .flex > div {
      padding: 15px;
    }

    .description {
      font-size: 12px;
      color: #a0a0a0;
      margin-top: 6px;

    }
    .accounting_finished {
      opacity: 0.3;
    }
    .name {
      display: inline;
    }
  }
  .title {
    font-weight: bold;
    font-size: 15px;
  }
}
</style>
